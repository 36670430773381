.purchase-container {
  min-height: 100vh;
  width: 100%;
  margin: 0;
  padding: 4rem 2rem;
  background-color: #FFF5E6;
  color: #8B4513;
  text-align: center;
  overflow-x: hidden;
  // max-width: 1200px;
  margin: 0 auto;

  &.hidden {
    .upload-section,
    > h1 {
      display: none;
    }
  }

  h1 {
    font-size: 2.5rem;
    margin-bottom: 4rem; // Increased margin to move content down
  }

  .upload-section {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .drag-drop-area {
      width: 100%;
      max-width: 600px;
      height: 300px;
      border: 2px dashed #FF8C00;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1rem 0;
      transition: background-color 0.3s ease;
      position: relative;
      overflow: hidden;

      &.drag-active {
        background-color: rgba(255, 140, 0, 0.1);
      }

      p {
        color: #8B4513;
        font-size: 1.4rem;
        z-index: 2;
      }

      .moving-circle, .moving-square {
        position: absolute;
        opacity: 0.1;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }

      .moving-circle {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: #FF8C00;
        animation-name: moveCircle;
      }

      .moving-square {
        width: 80px;
        height: 80px;
        background-color: #8B4513;
        animation-name: moveSquare;
      }
    }

    .button-group {
      display: flex;
      gap: 1rem;
      margin-top: 1rem;
    }

    .file-input {
      display: none;
    }

    .file-input-label, .upload-button {
      display: inline-block;
      background-color: #FF8C00;
      color: white;
      border: none;
      padding: 12px 24px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 1.2rem;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: darken(#FF8C00, 10%);
      }
    }
  }

  &.loading, &.error {
    text-align: center;
    font-size: 1.5rem;
    padding: 4rem;
  }

  &.error {
    color: #ffcccb;
  }

  .feedback-section {
    margin-top: 2rem;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 1rem;
    border-radius: 5px;

    h2 {
      font-size: 1.8rem;
      margin-bottom: 1rem;
    }

    .feedback-item {
      margin-bottom: 1.5rem;
      text-align: left;

      h3 {
        font-size: 1.4rem;
        margin-bottom: 0.5rem;
      }

      p {
        font-size: 1rem;
        line-height: 1.4;
      }
    }
  }

  .error-message {
    color: #ffcccb;
    margin-top: 1rem;
    font-size: 1rem;
  }

  // DatingProfileAnalysis styles
  .dating-profile-analysis {
    margin-top: 2rem;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    padding: 1rem;

    h1 {
      color: #FF8C00;
      margin-bottom: 10rem;
    }

    .analysis-container {
      display: flex;
      gap: 2rem;
      justify-content: center;

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    .text-analysis {
      flex: 1;
      max-width: 600px;
      text-align: left;
      padding-left: 2rem;

      ul {
        list-style-type: disc;
        padding-left: 1.25rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }

      li {
        margin-bottom: 0.5rem;
      }

      h3 {
        font-size: 1.4rem;
        font-weight: 600;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        color: #FF8C00;
      }

      .example {
        margin-top: 0.5rem;
        font-style: italic;
        background-color: rgba(255, 255, 255, 0.5);
        padding: 0.75rem;
        border-radius: 0.25rem;
        border-left: 4px solid #FF8C00;
      }
    }

    .chart-analysis {
      flex: 1;
      background-color: rgba(255, 255, 255, 0.2);
      padding: 1rem;
      border-radius: 5px;
      display: flex;
      flex-direction: column;

      @media (max-width: 768px) {
        order: -1;
      }
    }

    .analysis-section {
      margin-bottom: 1.5rem;
      border: 1px solid #FF8C00;
      border-radius: 0.5rem;
      overflow: hidden;
    }

    .section-toggle {
      width: 100%;
      padding: 1rem;
      text-align: left;
      background-color: #FF8C00;
      color: white;
      transition: background-color 0.2s;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: none;
      cursor: pointer;
      font-size: 1.2rem;
      font-weight: bold;

      &:hover {
        background-color: darken(#FF8C00, 10%);
      }

      h2 {
        margin: 0;
        font-size: 1.2rem;
      }
    }

    .section-content {
      padding: 1rem;
      background-color: rgba(255, 255, 255, 0.5);
      color: #8B4513;
    }

    .download-button,
    .email-input,
    .email-button {
      width: 100%;
      padding: 0.75rem;
      margin-top: 1rem;
      border-radius: 5px;
      font-size: 1rem;
      font-weight: bold;
    }

    .download-button,
    .email-button {
      background-color: #FF8C00;
      color: white;
      border: none;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: darken(#FF8C00, 10%);
      }

      &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
      }
    }

    .email-input {
      border: 1px solid #FF8C00;
      color: #8B4513;
      background-color: rgba(255, 255, 255, 0.5);
      font-weight: normal;
    }

    .email-success {
      color: #28a745;
      margin-top: 0.5rem;
      font-weight: bold;
    }
  }
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #FF8C00;
  overflow-x: hidden;
}

.loading-content {
  text-align: center;
}

.spinner {
  width: 6rem;
  height: 6rem;
  border: 0.5rem solid #fff;
  border-top: 0.5rem solid transparent;
  border-radius: 50%;
  margin: 0 auto 2rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-content h2 {
  font-size: 1.875rem;
  font-weight: bold;
  color: #8B4513;
  margin-bottom: 1rem;
}

.loading-content p {
  font-size: 1.25rem;
  color: #8B4513;
}

@keyframes moveCircle {
  0% { transform: translate(-50px, -50px) rotate(0deg); }
  100% { transform: translate(650px, 350px) rotate(360deg); }
}

@keyframes moveSquare {
  0% { transform: translate(650px, 350px) rotate(0deg); }
  100% { transform: translate(-50px, -50px) rotate(360deg); }
}
