/* TinderOptimizerLanding.css */

.landing-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .hero-section {
    padding: 30px 0; // Reduced padding from 60px to 30px
  }
  
  .hero-content {
    display: flex;
    gap: 40px;
    align-items: flex-start; /* Changed from center to flex-start */
  }
  
  .hero-text {
    flex: 1;
    padding-top: 0; // Removed padding-top
  }
  
  h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 15px; // Reduced margin-bottom from 20px to 15px
  }
  
  .hero-text p {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 20px;
  }
  
  .hero-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .button.primary {
    background-color: #007bff;
    color: white;
  }
  
  .button.primary:hover {
    background-color: #0056b3;
  }
  
  .pricing-section {
    background-color: #f8f9fa;
    padding: 60px 0;
  }
  
  .section-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .tag {
    display: inline-block;
    padding: 5px 10px;
    background-color: #e9ecef;
    border-radius: 20px;
    font-size: 0.9rem;
    margin-bottom: 10px;
  }
  
  h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .section-header p {
    font-size: 1.1rem;
    color: #666;
  }
  
  .pricing-plans {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
  }
  
  .pricing-plan {
    background-color: white;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .plan-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .plan-header h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .price {
    font-size: 2rem;
    font-weight: bold;
  }
  
  .price-period {
    color: #666;
  }
  
  .plan-features {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
  }
  
  .plan-features li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .icon {
    margin-right: 10px;
  }
  
  .icon.check {
    color: #28a745;
  }
  
  .icon.x {
    color: #dc3545;
  }
  
  .full-width {
    width: 100%;
  }
  
  /* Add media queries for responsiveness */
  @media (max-width: 768px) {
    .hero-content {
      flex-direction: column;
    }
    
    .hero-image {
      order: -1;
    }
  }

/* Add media queries for responsiveness */
@media (max-width: 768px) {
  .how-it-works {
    .steps {
      flex-direction: column;
      align-items: center;
    }

    .step {
      max-width: 100%;
    }
  }
}

.home-page {
  background-color: #FFF5E6;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;

  .content-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }

  .main-content {
    max-width: 800px;
    width: 100%;
    text-align: center;
  }

  .text-content {
    h1 {
      font-size: 3rem;
      font-weight: bold;
      margin-bottom: 20px;
      color: #FF8C00;
    }

    p {
      font-size: 1.2rem;
      color: #8B4513;
      margin-bottom: 30px;
    }

    .features {
      list-style-type: none;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;

      li {
        font-size: 1.1rem;
        color: #8B4513;
        margin-bottom: 15px;

        &:before {
          content: "✓";
          color: #FF8C00;
          margin-right: 10px;
          font-weight: bold;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .home-page {
    .text-content {
      h1 {
        font-size: 2.5rem;
      }

      p {
        font-size: 1.1rem;
      }

      .features li {
        font-size: 1rem;
      }
    }
  }
}

// Keep other existing styles...