.app-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 1rem 0; // Remove horizontal padding
    z-index: 10;
    background-color: #FFF5E6;
    transition: transform 0.3s ease-in-out;

    &.hidden {
        transform: translateY(-100%);
    }
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem; // Add padding to the content instead
}

.logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: #8B4513;
}

nav {
    display: flex;
    gap: 1rem;
    align-items: center;

    a {
        color: #8B4513;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

@media (max-width: 768px) {
    .header-content {
        flex-direction: row; // Keep it as row
        justify-content: space-between; // Space between logo and nav
        padding: 0 1rem; // Reduce padding on smaller screens
    }
}