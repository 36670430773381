.how-it-works {
  background-color: #FFF5E6;
  padding: 60px 0;
  text-align: center;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #FF8C00;
  }

  .steps {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 60px;
  }

  .step {
    flex: 1;
    min-width: 200px;
    max-width: 250px;
    margin: 20px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    .step-icon {
      width: 50px;
      height: 50px;
      background-color: #FF8C00;
      color: white;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      font-weight: bold;
      margin: 0 auto 20px;
    }

    h3 {
      font-size: 1.2rem;
      margin-bottom: 10px;
      color: #FF8C00;
    }

    p {
      font-size: 1rem;
      color: #8B4513;
    }
  }

  .visual-example {
    max-width: 800px;
    margin: 0 auto;

    h3 {
      font-size: 2rem;
      margin-bottom: 30px;
      color: #FF8C00;
    }

    .example-container {
      background-color: white;
      border-radius: 10px;
      padding: 30px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .before-after {
      display: flex;
      justify-content: space-between;
      gap: 40px;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      .before, .after {
        flex: 1;
        text-align: center;

        h4 {
          font-size: 1.5rem;
          margin-bottom: 15px;
          color: #FF8C00;
        }

        img {
          width: 100%;
          max-width: 300px;
          height: auto;
          border-radius: 10px;
          margin-bottom: 15px;
        }

        p {
          font-size: 1rem;
          color: #8B4513;
          font-style: italic;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .how-it-works {
    .steps {
      flex-direction: column;
      align-items: center;
    }

    .step {
      max-width: 100%;
    }
  }
}