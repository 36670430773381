.purchase-container {
  min-height: 100vh;
  width: 100%;
  padding: 2rem 1rem;
  background-color: #f8f9fa; // Updated to match HowItWorks background color
  color: #333; // Changed to a darker color for better contrast
  text-align: center;
  overflow-x: hidden;
  margin: 0 auto;

  &.hidden {
    .upload-section,
    > h1 {
      display: none;
    }
  }

  h1 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .upload-section {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .drag-drop-area {
      width: 100%;
      max-width: 500px;
      height: 200px;
      border: 2px dashed #007bff; // Changed to match the primary button color
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1rem 0;
      transition: background-color 0.3s ease;
      position: relative;
      overflow: hidden;

      &.drag-active {
        background-color: rgba(0, 123, 255, 0.1);
      }

      p {
        color: #333;
        font-size: 1.2rem;
        z-index: 2;
      }

      .moving-circle, .moving-square {
        position: absolute;
        opacity: 0.1;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }

      .moving-circle {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: #007bff; // Changed to match the primary button color
        animation-name: moveCircle;
      }

      .moving-square {
        width: 60px;
        height: 60px;
        background-color: #333;
        animation-name: moveSquare;
      }
    }

    .button-group {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      margin-top: 1rem;
      justify-content: center;
    }

    .file-input {
      display: none;
    }

    .file-input-label, .upload-button {
      display: inline-block;
      background-color: #007bff; // Changed to match the primary button color
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 1rem;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }
  }

  .error-message {
    color: #ff4d4d;
    margin-top: 1rem;
    font-size: 1rem;
  }

  .dating-profile-analysis {
    margin-top: 2rem;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    padding: 1rem;

    h1 {
      color: #007bff; // Changed to match the primary button color
      margin-bottom: 2rem;
    }

    .analysis-container {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      justify-content: center;

      .text-analysis,
      .chart-analysis {
        flex: 1 1 100%;
        max-width: 600px;
        text-align: left;
        margin-bottom: 1rem;
      }

      @media (min-width: 768px) {
        .text-analysis,
        .chart-analysis {
          flex: 1;
          max-width: 45%;
        }
      }
    }

    .text-analysis {
      ul {
        list-style-type: disc;
        padding-left: 1.25rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }

      li {
        margin-bottom: 0.5rem;
      }

      h3 {
        font-size: 1.2rem;
        font-weight: 600;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        color: #007bff; // Changed to match the primary button color
      }

      .example {
        margin-top: 0.5rem;
        font-style: italic;
        background-color: rgba(255, 255, 255, 0.5);
        padding: 0.75rem;
        border-radius: 0.25rem;
        border-left: 4px solid #007bff; // Changed to match the primary button color
      }
    }

    .chart-analysis {
      background-color: rgba(255, 255, 255, 0.2);
      padding: 1rem;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .analysis-section {
      margin-bottom: 1.5rem;
      border: 1px solid #007bff; // Changed to match the primary button color
      border-radius: 0.5rem;
      overflow: hidden;
    }

    .section-toggle {
      width: 100%;
      padding: 1rem;
      text-align: left;
      background-color: #007bff; // Changed to match the primary button color
      color: white;
      transition: background-color 0.2s;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: none;
      cursor: pointer;
      font-size: 1rem;
      font-weight: bold;

      &:hover {
        background-color: #0056b3;
      }

      h2 {
        margin: 0;
        font-size: 1rem;
      }
    }

    .section-content {
      padding: 1rem;
      background-color: rgba(255, 255, 255, 0.5);
      color: #333;
    }

    .download-button,
    .email-input,
    .email-button {
      width: 100%;
      padding: 0.75rem;
      margin-top: 1rem;
      border-radius: 5px;
      font-size: 1rem;
      font-weight: bold;
    }

    .download-button,
    .email-button {
      background-color: #007bff; // Changed to match the primary button color
      color: white;
      border: none;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: #0056b3;
      }

      &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
      }
    }

    .email-input {
      border: 1px solid #007bff; // Changed to match the primary button color
      color: #333;
      background-color: rgba(255, 255, 255, 0.5);
      font-weight: normal;
    }

    .email-success {
      color: #28a745;
      margin-top: 0.5rem;
      font-weight: bold;
    }
  }
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #007bff; // Changed to match the primary button color
  overflow-x: hidden;
}

.loading-content {
  text-align: center;
  padding: 1rem;
}

.spinner {
  width: 4rem;
  height: 4rem;
  border: 0.5rem solid #fff;
  border-top: 0.5rem solid transparent;
  border-radius: 50%;
  margin: 0 auto 1rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-content h2 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
}

.loading-content p {
  font-size: 1rem;
  color: #333;
}

@keyframes moveCircle {
  0% { transform: translate(-50px, -50px) rotate(0deg); }
  100% { transform: translate(650px, 350px) rotate(360deg); }
}

@keyframes moveSquare {
  0% { transform: translate(650px, 350px) rotate(0deg); }
  100% { transform: translate(-50px, -50px) rotate(360deg); }
}

.pricing-section {
  background-color: #FFF5E6;
  padding: 60px 0;
  text-align: center;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #FF8C00;
  }

  .pricing-plans {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 60px;
  }

  .pricing-plan {
    flex: 1;
    min-width: 250px;
    max-width: 300px;
    margin: 20px;
    padding: 30px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    h3 {
      font-size: 1.8rem;
      margin-bottom: 20px;
      color: #FF8C00;
    }

    .price {
      font-size: 2.5rem;
      font-weight: bold;
      margin-bottom: 20px;
      color: #8B4513;

      span {
        font-size: 1rem;
        font-weight: normal;
        color: #8B4513;
      }
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin-bottom: 30px;

      li {
        margin-bottom: 10px;
        font-size: 1rem;
        color: #8B4513;

        &::before {
          content: "✓";
          color: #FF8C00;
          margin-right: 10px;
          font-weight: bold;
        }
      }
    }

    .learn-more-btn {
      background-color: #FF8C00;
      color: white;
      border: none;
      padding: 12px 24px;
      border-radius: 25px;
      font-size: 1.1rem;
      cursor: pointer;
      transition: background-color 0.3s ease;
      width: 100%;

      &:hover {
        background-color: #e67e00;
      }
    }
  }
}

@media (max-width: 768px) {
  .pricing-section {
    .pricing-plans {
      flex-direction: column;
      align-items: center;
    }

    .pricing-plan {
      max-width: 100%;
    }
  }
}

.message {
  text-align: center;
  padding: 60px 0;
  background-color: #FFF5E6;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 1.5rem;
    color: #8B4513;
    max-width: 600px;
    margin: 0 auto;
    padding: 30px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
}
